import React from 'react';
import './index.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import * as icons from '@fortawesome/free-solid-svg-icons';

class FontAwesomeButton extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        const faIcon = icons[this.props.faIcon];
        return (
            <FontAwesomeIcon
                className="FontAwesomeButton"
                fixedWidth={this.props.fixedWidth}
                icon={faIcon}
                size={this.props.size}
                onClick={this.props.onClickExecute.bind(this)}
            />
        );
    }

}

export default FontAwesomeButton;