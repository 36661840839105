import React, {Component} from 'react';
import axios from 'axios';
import qs from 'qs';
import ForgeViewer from './Components/forge-viewer/index';
import ActionBarContainer from "./Containers/action-bar";

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            view: null,
            listoIsolate: []
        }
        this.forgeViewer = React.createRef();
    }


    handleViewerError(error) {
        console.log('Error loading viewer.');
    }

    /**
     * After the viewer loads a document,
     * we need to select which viewable to display in our component
     * @param doc
     * @param viewables
     */
    handleDocumentLoaded(doc, viewables) {
        if (viewables.length === 0) {
            console.error('Document contains no viewables.');
        }
        else {
            //Select the first viewable in the list to use in our viewer component
            this.setState({view: viewables[0]});
        }
    }

    handleDocumentError(viewer, error) {
        console.log('Error loading a document');
    }

    handleModelLoaded(viewer, model) {

        console.log('Loaded model:', model);
    }

    handleModelError(viewer, error) {
        console.log('Error loading the model.');
    }

    getForgeToken() {
        return  new Promise((resolve, reject) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        
        var urlencoded = new URLSearchParams();
        urlencoded.append("grant_type", "client_credentials");
        urlencoded.append("client_id", "tRa4jWzL8JfVOIJwGHbLGjIMCqG24PzK");
        urlencoded.append("client_secret", "mjieZaNM0WfuAYoV");
        urlencoded.append("scope", 'viewables:read');
        
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: urlencoded,
          redirect: 'follow'
        };
        
        fetch("https://developer.api.autodesk.com/authentication/v1/authenticate", requestOptions)
          .then(response => response.text())
          .then(result =>{
              resolve(JSON.parse(result));
            })
          });
    }

    /**
     *  Once the viewer has initialized, it will ask us for a forge token so it can
     access the specified document.
     * @param onAccessToken
     */

    async handleTokenRequested(onAccessToken) {
        if (onAccessToken) {
            let token =await this.getForgeToken();
            if (token)
                onAccessToken(
                    token.access_token, token.expires_in);
        }
    }

    /**
     * Executed when an element is selected in viewer and onSelectIsolateSystem = true
     * Retrieves elements in system to be selected
     * @param elementId
     */
    async handleRequestSystem(elementId) {
        console.log('System elements will be retrieve:', elementId);
        return [3931, 4818, 4820];
    }

    /**
     * Cleans isolation executed from eye button
     */
    handleClearIsolated() {
        this.forgeViewer.current.clearSelection();
        this.forgeViewer.current.cleanIsolated();
    }

    render() {
        return (
            <div className="App">
                <ForgeViewer
                    ref={this.forgeViewer}
                    version="6.0"
                    urn="dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6dHJhNGp3emw4amZ2b2lqd2doYmxnamltY3FnMjRwemtfdHV0b3JpYWxfYnVja2V0L0RlbW8lMjBGQi1HRENDJTIwTW9kZWwucnZ0"
                    view={this.state.view}
                    qualityLow={true}
                    startingMessage={'Starting Viewer...'}
                    headless={true}
                    onSelectIsolateSystem={true}
                    perspectiveCamera={true}
                    listToIsolate={this.state.listoIsolate}
                    onViewerError={this.handleViewerError.bind(this)}
                    onTokenRequest={this.handleTokenRequested.bind(this)}
                    onDocumentLoad={this.handleDocumentLoaded.bind(this)}
                    onDocumentError={this.handleDocumentError.bind(this)}
                    onModelLoad={this.handleModelLoaded.bind(this)}
                    onModelError={this.handleModelError.bind(this)}
                    onRequestSystem={this.handleRequestSystem.bind(this)}
                />

                <ActionBarContainer
                    onClearIsolation={this.handleClearIsolated.bind(this)}
                />
            </div>
        )
    }
}

export default App
//https://docs.b360.autodesk.com/projects/6f114eb1-69fc-47b9-b8fa-d615a871be96/folders/urn:adsk.wipprod:fs.folder:co.Ws6R9bIeQLu7dqZJ-z6SAw/detail/viewer/items/urn:adsk.wipprod:dm.lineage:tpCyEEAlSOW8V4iLTs07YQ