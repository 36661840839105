import React from 'react';
import './index.css';
import FontAwesomeButton from "../../Components/fontAwesome-button";
class ActionBarContainer extends React.Component {

    constructor(props) {
        super(props);

    }
    handleInfoClick(){
        console.log("this.handleInfoClick()")
    }
    handleEyeClick(){
        this.props.onClearIsolation()
    }
    render() {
        const infoClick= this.handleInfoClick.bind(this);
        const eyeClick= this.handleEyeClick.bind(this);
        return (<div className="ActionBar">
            <FontAwesomeButton
                faIcon="faInfoCircle"
                size="2x"
                fixedWidth="false"
                onClickExecute={infoClick}
           />
            <FontAwesomeButton
                faIcon="faEye"
                size="2x"
                fixedWidth="false"
                onClickExecute={eyeClick}
           />
        </div>);
    }

}

export default ActionBarContainer;